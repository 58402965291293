.aboutMenu {
  margin: 80px 0px;
}
.aboutMenu p {
  max-width: 400px;
  color: #666666;
  line-height: 30px;
  margin-top: 20px;
}
/* ********************************************Responsive****************************************/
/* ******************************************* 992 - 1199 **************************************/

@media (min-width: 992px) and (max-width: 1199px) {
  
  .aboutMenu {
    margin: 60px 0px;
  }
  .aboutMenu p {
    line-height: 25px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
  }
  .titleFont h2{
    font-size: 25px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
  .aboutMenu p {
    font-size: 15px;
    line-height: 24px;
    margin-top: 0px;
  }
  .titleFont h2{
    font-size: 28px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
  .aboutMenu {
    margin: 40px 0px;
}   
.colForResponsive{
  margin-top: 20px;
}
.aboutMenu p{
  margin-top: 0px;
  font-size: 14px;
  line-height: 25px;
}
}
